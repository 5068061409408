export default {
  data() {
    return {
      statusOptions: [
        { name: 'Commande', value: 'UNVERIFIED' },
        { name: 'Paiement reçu', value: 'VERIFIED' },
        { name: 'En cours de fabrication', value: 'PROCESSING' },
        { name: 'En attente des infos de baptême', value: 'MISSING-BAPTISM-INFO' },
        { name: 'En attente photo(s) pour cadre(s)', value: 'MISSING-FRAME-INFO' },
        { name: 'En attente de vos infos complémentaires', value: 'MISSING-INFO' },
        { name: 'Finalisation', value: 'FINISHED' },
        { name: 'Expédiée', value: 'SHIPPED' },
        { name: 'Livrée', value: 'RECEIVED' },
        { name: 'Annulée', value: 'CANCELLED' },
        { name: 'Remboursée', value: 'REIMBURSED' },
        { name: 'Échangé par un bon cadeau', value: 'REPLACED' },
      ],
      closedOptions: [
        'SHIPPED',
        'RECEIVED',
        'CANCELLED',
        'REIMBURSED',
        'REPLACED',
      ],
      stopOptions: [
        'CANCELLED',
        'REIMBURSED',
        'REPLACED',
      ],
    };
  },

  methods: {
    closedStatus(status) {
      return this.closedOptions.includes(status);
    },

    stoppedStatus(status) {
      return this.stopOptions.includes(status);
    },

    prettyStatus(status) {
      let newStatus = '';

      switch(status) {
        case 'VERIFIED': newStatus = 'Paiement reçu'; break;
        case 'UNVERIFIED': newStatus = 'Commande'; break;
        case 'PROCESSING': newStatus = 'En cours de fabrication'; break;
        case 'MISSING-BAPTISM-INFO': newStatus = 'En attente des infos de baptême'; break;
        case 'MISSING-FRAME-INFO': newStatus = 'En attente photo(s) pour cadre(s)'; break;
        case 'MISSING-INFO': newStatus = 'En attente de vos infos complémentaires'; break;
        case 'FINISHED': newStatus = 'Finalisation'; break;
        case 'SHIPPED': newStatus = 'Expédiée'; break;
        case 'RECEIVED': newStatus = 'Livrée'; break;
        case 'CANCELLED': newStatus = 'Annulée'; break;
        case 'REIMBURSED': newStatus = 'Remboursée'; break;
        case 'REPLACED': newStatus = 'Échangé par un bon cadeau'; break;
        default: newStatus = 'Commande';
      }

      return newStatus;
    },
  },
};
