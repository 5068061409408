import axios from 'axios';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  created() {
    this.debounceName = debounce(this.debounceName, 500);
  },

  computed: {
    ...mapGetters({
      baseurl: 'app/baseurl',
      url: 'app/url',
    }),
  },

  methods: {
    drawCanvas({
      typo = 'hh',
      color = '#f9e0e1',
      thickness = 't02',
      target = [],
      loading = false,
    }) {
      if (!target.length) return;

      target.forEach(t => {
        if (t.container && t.container.querySelector('.loader')) return;
        const imgHTML = t.container;

        const loader = document.createElement('div');
        loader.classList.add('loader');
        loader.innerHTML = `<div class="mask">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>`;

        imgHTML.appendChild(loader);
      });

      if (loading) {
        this.debounceName({ typo, color, thickness, target });
        return;
      }

      this.render({ typo, color, thickness, target });
    },

    debounceName({ typo, color, thickness, target }) {
      this.render({ typo, color, thickness, target });
    },

    render({ typo, color, thickness, target }) {
      target.forEach(async t => {
        if (t.name && t.name === '') return;
        const imgHTML = t.container;

        const loader = imgHTML.querySelector('.loader');

        const size = this.product?.size?.value || 4;
        const width = this.product?.width?.value || null;

        if (t.name === 'Widddi') {
          const defaultImg = document.createElement('img');

          defaultImg.src = `${this.url}/img/default.png`;
          defaultImg.style.maxHeight = this.sizeFiles({
            name: t.name,
            typo,
            size,
            width,
          });

          if (loader) {
            loader.remove();
          }

          if (imgHTML.childNodes.length) {
            imgHTML.replaceChild(defaultImg, imgHTML.children[0]);
            return;
          }

          imgHTML.appendChild(defaultImg);

          return;
        }

        const config = {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'image/png',
          },
        };

        const instance = axios.create(config);

        const response = await instance.get(
          `${this.baseurl}/renders/name`,
          {
            params: {
              name: t.name,
              typo,
              color: color.substring(1),
              thickness,
            },
          },
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'image/png' }),
        );

        const img = document.createElement('img');
        img.src = url;
        img.style.maxHeight = this.sizeFiles({
          name: t.name,
          typo,
          size,
          width,
        });

        if (loader) loader.remove();

        if (imgHTML.childNodes.length) {
          imgHTML.replaceChild(img, imgHTML.children[0]);
          return;
        }

        imgHTML.appendChild(img);
      });
    },

    sizeFiles({ name, typo, size, width = null }) {
      const hasDownLetters = this.hasDownLetters(name, typo);
      const minHeightValue = hasDownLetters ? 100 : 55;

      return width
        ? `${minHeightValue + 2.5 * width}px`
        : `${minHeightValue + 30 + 100 * (size / 10)}px`;
    },

    hasDownLetters(name, typo) {
      const downLetterByFont = {
        hh: ['J', 'Y', 'f', 'g', 'j', 'p', 'q', 'y', 'z'],
        typo2: ['Y', 'f', 'g', 'j', 'p', 'q', 'y'],
        typo3: ['G', 'J', 'Y', 'f', 'g', 'j', 'p', 'q', 'y', 'z'],
        typo4: ['f', 'g', 'j', 'p', 'q', 'y', 'z'],
        typo5: ['Y', 'J', 'f', 'g', 'j', 'p', 'q', 'y', 'z'],
        typo6: [],
        typoCustom: [],
      };

      return name
        .split('')
        .some(r => downLetterByFont[typo].indexOf(r) >= 0);
    },
  },
};
