export default {
  methods: {
    arrayMove(arr, previousIndex, newIndex) {
      const array = arr.slice(0);
      if (newIndex >= array.length) {
        let k = newIndex - array.length;
        while (k-- + 1) {
          array.push(undefined);
        }
      }
      array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
      return array;
    },
    findIndexInData(data, property, value) {
      const l = data.length;
      for (let i = 0; i < l; i++) {
        if (data[i][property] === value) {
          return i;
        }
      }
      return -1;
    },
    objLength(obj) {
      let size = 0;

      Object.keys(obj).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) size++;
      });

      return size;
    },
    findIndexInObject(obj, value) {
      const l = this.objLength(obj);
      for (let i = 0; i < l; i++) {
        if (Object.entries(obj)[i][0] === value) {
          return i;
        }
      }
      return -1;
    },
  },
};
