export default {
  data() {
    return {
      rounded: '',
    };
  },

  methods: {
    roundNumber(num, scale = 2) {
      return +(`${Math.round(`${num}e+${scale}`)}e-${scale}`);
    },
  },
};
