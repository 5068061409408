export default {
  data() {
    return {
      activeStep: 0,
    };
  },

  methods: {
    chooseStep(step) {
      this.activeStep = step;

      const stepRef = document.getElementById('steps');

      if (!stepRef) {
        return;
      }

      this.$nextTick(() => {
        const stepOffset = this.getCoords(stepRef);

        window.scrollTo({ top: stepOffset.top - 90, behavior: 'smooth' });
      });
    },

    getCoords(elem = document) {
      const box = elem.getBoundingClientRect();

      const body = document.body;
      const docEl = document.documentElement;

      const scrollTop =
        window.scrollY || docEl.scrollTop || body.scrollTop;
      const scrollLeft =
        window.scrollX || docEl.scrollLeft || body.scrollLeft;

      const clientTop = docEl.clientTop || body.clientTop || 0;
      const clientLeft = docEl.clientLeft || body.clientLeft || 0;

      const top = box.top + scrollTop - clientTop;
      const left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    },
  },
};
