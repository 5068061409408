export default {
  data() {
    return {
      formErr: [],
    };
  },

  methods: {
    getErrors(formId) {
      this.formErr = [];
      const form = document.getElementById(formId).elements;
      for (let i = 0; i < form.length; i++) {
        if (
          form[i].type === 'text'
          || form[i].type === 'radio'
          || form[i].type === 'checkbox'
          || form[i].type === 'email'
        ) {
          const { id } = form[i];
          const label = document.querySelector(`label[for="${id}"]`).innerText;
          const err = this.getFormatError(form[i]);
          if (form[i].required && form[i].value === '') {
            this.formErr.push({
              name: label,
              error: err,
            });
          }
          if (err !== '') {
            this.formErr.push({
              name: label,
              error: err,
            });
          }
        }
      }

      return this.formErr;
    },

    getFormatError(el) {
      let err = '';
      const phoneRegex = /^(\+|0)[0-9]{8,14}$/g;
      const mailRegex = /\S+@\S+\.\S+/;

      if (el.getAttribute('data-filter') !== undefined) {
        switch (el.getAttribute('data-filter')) {
          case 'phone':
            err = phoneRegex.exec(el.value) === null
              ? 'Le numéro de téléphone ne doit comporter que des chiffres, sans espace.' : '';
            break;
          case 'email':
            err = mailRegex.exec(el.value) === null ? 'L\'adresse e-mail ne semble pas correcte.' : '';
            break;
          default:
            err = '';
        }
      }

      return err;
    },
  },
};
