export default {
  methods: {
    getDate(date) {
      if (typeof date === 'string') {
        let splitted = date.split('/');

        if (splitted[2]) date = `${splitted[1]}/${splitted[0]}/${splitted[2]}`;
      }
      const fullDate = new Date(date);

      const day = fullDate.getDate();
      const month = fullDate.getMonth();
      const year = fullDate.getFullYear();
      const hour = fullDate.getHours();
      const minutes = fullDate.getMinutes();

      return {day, month, year, hour, minutes, fullDate};
    },

    prettyDate(date = Date.now()) {
      const newDate = this.getDate(date);

      const month = (newDate.month + 1).toString().padStart(2, '0');

      return `${newDate.day}/${month}/${newDate.year}`;
    },

    prettyFullDate(date = Date.now()) {
      const newDate = this.getDate(date);

      const month = (newDate.month + 1).toString().padStart(2, '0');

      return `${newDate.day}/${month}/${newDate.year} ${newDate.hour}:${newDate.minutes}`;
    },

    stringPrettyDate(date = Date.now()) {
      const newDate = this.getDate(date);

      const month = newDate.fullDate.toLocaleString('fr-fr', { month: 'short' });

      return `${newDate.day} ${month} ${newDate.year}`;
    }
  }
};
