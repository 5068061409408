import { dateHelper } from '@/lib/moment.js';

export default {
  computed: {
    currentDate() {
      return dateHelper();
    },

    expirationDate() {
      return dateHelper('01/09/2023', 'L');
    },

    lessThan1Day() {
      const timeLeft = dateHelper(this.expirationDate).diff(
        this.currentDate,
        'hours',
      );

      return timeLeft < 24 && timeLeft > 0;
    },

    onHolidays() {
      return (
        dateHelper(this.expirationDate).diff(this.currentDate, 'secondes') < 0 &&
        dateHelper(this.comeBackDate).diff(this.currentDate, 'secondes') > 0
      );
    },

    comeBackDate() {
      return dateHelper('26/09/2023', 'L');
    },

    lessThan1DayBeforeWork() {
      const timeLeft = dateHelper(this.comeBackDate).diff(this.currentDate, 'hours');

      return timeLeft < 24 && timeLeft > 0;
    },

    nbDaysLeft() {
      if (this.onHolidays) {
        return this.lessThan1DayBeforeWork
          ? dateHelper(this.comeBackDate).diff(this.currentDate, 'hours')
          : dateHelper(this.comeBackDate).diff(this.currentDate, 'days');
      }

      return this.lessThan1Day
        ? dateHelper(this.expirationDate).diff(this.currentDate, 'hours')
        : dateHelper(this.expirationDate).diff(this.currentDate, 'days');
    },
  },
};
