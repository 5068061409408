import * as Sentry from '@sentry/browser';

export default {
  methods: {
    handleErrors(errors) {
      if (process.env.NODE_ENV === 'development') {
        console.log({ ...errors });
        return;
      }

      Sentry.captureException(JSON.stringify(errors));
    },
  },
};
