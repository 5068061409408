import { v4 as uuidv4 } from 'uuid';

export default {
  methods: {
    hashAdress(firstname, lastname) {
      const date = new Date();

      const hash = firstname.slice(0, 2).toLowerCase()
        + lastname.slice(0, 2).toLowerCase()
        + date.getDate()
        + date.getMonth()
        + date.getHours()
        + date.getMinutes();

      return hash;
    },
    basicHash() {
      return uuidv4();
    },

    hashCoupon() {
      let hash = '';
      const prefix = [
        'SMAK',
        'BLU',
        'CLAP',
        'CLIC',
        'HIP',
        'MIAM',
        'TAP',
        'TIC',
        'MEU',
        'ZAP',
        'WUW',
        'BANG',
        'WHAM',
        'BLA',
        'PING',
      ];

      hash = prefix[this.getRandomInt(prefix.length)];

      const char = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';

      for (let i = 0; i < 5; i++) {
        hash += char[this.getRandomInt(char.length)];
      }

      return hash;
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
  },
};
