export default {
  data() {
    return {
      data: '',
    };
  },

  methods: {
    errorMessage(errorCode) {
      let msg = '';
      switch (errorCode) {
        case 'auth/email-already-in-use':
          msg = 'Cette adresse e-mail existe déjà.';
          break;
        case 'auth/invalid-email':
          msg = 'Cette adresse e-mail ne semble pas être au bon format.';
          break;
        case 'auth/operation-not-allowed':
          msg = 'Une erreur est survenue.';
          break;
        case 'auth/weak-password':
          msg = 'Votre mot de passe doit comporter au minimum 6 caractères.';
          break;
        case 'auth/wrong-password':
          msg = 'Mauvais mot de passe.';
          break;
        case 'auth/user-not-found':
          msg = 'Cette adresse e-mail n\'existe pas.';
          break;
        default:
          msg = 'Une erreur est survenue';
      }

      return msg;
    },
  },
};
